<template>

    <div class="cursor-pointer flex items-center w-5" >
        <button v-if="isDark" @click="toggleDark()">
            <font-awesome-icon :icon="['far', 'sun-bright']" class="dark:text-white text-xl hover:dark:text-purple-400 hover:text-purple-600" />
        </button>

        <button v-if="!isDark" @click="toggleDark()">
            <font-awesome-icon :icon="['fas', 'moon']" class="dark:text-white text-xl hover:dark:text-purple-400 hover:text-purple-600" />
        </button>
    </div>

</template>


<script setup>

import { useDark, useToggle } from "@vueuse/core"

const isDark = useDark()

const toggleDark = useToggle(isDark)

</script>

<style scoped>

</style>
